export const defaultRoutes = {
	LOGIN: "/login",
	ADVENT: "/advent",
	CART: "/cart",
	OFFERS: "/disposition",
	ORDER: "/order",
	TERMS_AND_CONDITIONS: "/termsandconditions",
	WATCH_LISTS: "/watchlists",
	GTA: "/gta",
	PROMOTION: "/promotion",
	PROMOTION_PATH: "/promotion/:promotionId",
	PROMOTION_ORDER_PATH: "/promotion/:promotionId/order/:packageId",
	CATALOGS: "/catalogs",
	MEDIA_LIB: "/medialib",
	RESHIPMENT: "/reshipment",
	SUSY: "/susy",
	PROFILE: "/profile",
	NEWS: "/news",
	NEWS_PATH: "/news/:newsId?",
	RELEASE_NOTES_PATH: "/releaseNote/:releaseId?",
	MAINTENANCE_LISTS: "/maintenanceLists",
	MAINTENANCE_GUIDE_TIMES: "/maintenanceguidetimes",
	DASHBOARD: "/",
	PROPOSALS: "/proposal",
	WHEELS: "/wheel",
	ABBREVIATIONS: "/abbreviations",
	PRODUCTS: "/products",
	SALES_PROMOTIONS: "/salespromotion",
};

class RouteProvider {
	dictionary;

	constructor() {
		this.dictionary = defaultRoutes;
	}

	setRoutes = nextDictionary => this.dictionary = nextDictionary;

	getRoutes() {
		return this.dictionary;
	}
}

const RouteProviderInstance = new RouteProvider();

export default RouteProviderInstance;
