// @flow
import type {Axios, AxiosPromise} from "axios";
import type {EnhancedOrder, Offer, OfferBundle, Order, OrderStatus, UnfulfilledOffer} from "../models";
import {CreateOrderRequest} from "../models";
import orderEnhancer from "../service/orderEnhancer";
import type {DetailedProduct} from "../../service/productService";
import productService from "../../service/productService";
import type {EnhancedCartItemModel} from "../../cart/models";
import {axiosInstance} from "../../api";

class OrderService {
	api: Axios;

	constructor() {
		this.api = axiosInstance;
	}

	optimizeCart = (basketItems: EnhancedCartItemModel[]): Promise<OfferBundle> => {
		return this.api
			.post("/order/optimize", {
				basketItems
			})
			.then(response => {
				return this.enhanceOfferBundle(response.data);
			});
	};

	mapNamesToProducts = (detailedProducts: DetailedProduct[], offer: Offer | UnfulfilledOffer) => {
		offer.products.forEach(product => {
			const detailedProduct = detailedProducts.find(detailedProduct => detailedProduct.partNumber === product.productNumber);
			if (detailedProduct) {
				product.name = detailedProduct.product.name;
			}
		});
	};

	enhanceOfferBundle = (offerBundle: OfferBundle): Promise<OfferBundle> => {
		const partNumbers = offerBundle.offers.flatMap(offer => offer.products.map(product => product.productNumber));
		return productService.getDetailedProducts(partNumbers, false, false, false).then(detailedProducts => {
			offerBundle.offers.forEach(offer => this.mapNamesToProducts(detailedProducts, offer));
			offerBundle.unfulfilledOffers.forEach(unfulfilledOffer => this.mapNamesToProducts(detailedProducts, unfulfilledOffer));
			return offerBundle;
		});
	};

	getOrderDocument = (orderId: string, download: boolean): AxiosPromise => {
		return this.api.get(`/order/${orderId}/document?download=${download}`, {
			responseType: "arraybuffer"
		});
	};

	getOrders = (startDate: Date, endDate: Date, state?: $Values<typeof OrderStatus>): Promise<Order[]> => {
		return this.api
			.get<string, Array<Order>>("/order", {
				params: {
					startDate: startDate.toISOString(),
					endDate: endDate.toISOString(),
					state
				}
			})
			.then(response => response.data);
	};

	enhanceOrder = (order: Order): Promise<EnhancedOrder[]> => {
		return orderEnhancer(order);
	};

	// TODO: handle response and don't return AxiosPromise
	cancelOrder = (orderId: string): AxiosPromise<void> => {
		return this.api.delete(`/order/${orderId}`);
	};

	// TODO: handle response and don't return AxiosPromise
	placeOrder = (orderId: string): AxiosPromise<void> => {
		return this.api.patch(`/order/${orderId}`);
	};

	// TODO: handle response and don't return AxiosPromise
	disposeOrder = (basketItems: EnhancedCartItemModel[]): AxiosPromise<any> => {
		return this.api.post("/order/dispose", {
			basketItems
		});
	};
	
	// TODO: handle response and don't return AxiosPromise
	createOrder = (order: CreateOrderRequest): AxiosPromise<any> => {
		return this.api.post("/order", 
			order
		);
	};
}

const OrderServiceInstance = new OrderService();
export default OrderServiceInstance;
