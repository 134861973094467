import proposalGuideTimeSearch, {ProposalMaintenanceGuideTimeSearchState} from "./proposalMaintenanceGuideTimeSearch";
import vehicleGuideTimeSearch, {VehicleMaintenanceGuideTimeSearch} from "./vehicleMaintenanceGuideTimeSearch";
import {combineReducers} from "redux";

const rootReducer = combineReducers({
	proposalGuideTimeSearch,
	vehicleGuideTimeSearch
});

export type MaintenanceGuideTimeSearchState = {
	proposalGuideTimeSearch: ProposalMaintenanceGuideTimeSearchState,
	vehicleGuideTimeSearch: VehicleMaintenanceGuideTimeSearch
}

export default rootReducer;