import type {AdventCalendarDoorModel, AdventCalendarModel} from "../model";
import {
	FETCH_ADVENT_CALENDAR,
	OPEN_ADVENT_CALENDAR_DOOR,
	SEND_ADVENT_CALENDAR_DOOR_CODE,
	SET_ADVENT_CALENDAR_DOOR_MODAL,
	SET_SELECTED_ADVENT_CALENDAR_DOOR
} from "../actions";

export type AdventCalendarState = {
	adventCalendar: AdventCalendarModel,
	isFetchingAdventCalendar: boolean,
	isDoorModalOpen: boolean,
	isSubmittingDoorCode: boolean,
	selectedDoor: AdventCalendarDoorModel,
}

const initialState: AdventCalendarState = {
	adventCalendar: null,
	isFetchingAdventCalendar: false,
	isDoorModalOpen: false,
	isSubmittingDoorCode: false,
	selectedDoor: null,
};
const updateDoorInCalendarDoors = (doors: AdventCalendarDoorModel[], updatedDoor: AdventCalendarDoorModel) => {
	return doors.map(door => {
		if (door.id === updatedDoor.id) {
			return updatedDoor;
		} else {
			return door;
		}
	});
};

const advent = (state: AdventCalendarState = initialState, action: any) => {
	switch (action.type) {
		case FETCH_ADVENT_CALENDAR.REQUEST:
			return {
				...state,
				isFetchingAdventCalendar: true
			};
		case FETCH_ADVENT_CALENDAR.SUCCESS:
			return {
				...state,
				isFetchingAdventCalendar: false,
				adventCalendar: action.payload,
			};
		case FETCH_ADVENT_CALENDAR.FAILURE:
			return {
				...state,
				isFetchingAdventCalendar: false,
			};
		case SET_ADVENT_CALENDAR_DOOR_MODAL:
			return {
				...state,
				isDoorModalOpen: action.payload
			};
		case OPEN_ADVENT_CALENDAR_DOOR.SUCCESS:
			return {
				...state,
				adventCalendar: {
					...state.adventCalendar,
					doors: updateDoorInCalendarDoors(state.adventCalendar.doors, action.payload)
				}
			};
		case SEND_ADVENT_CALENDAR_DOOR_CODE.REQUEST:
			return {
				...state,
				isSubmittingDoorCode: true,
			};
		case SEND_ADVENT_CALENDAR_DOOR_CODE.SUCCESS:
			return {
				...state,
				isSubmittingDoorCode: false,
				selectedDoor: action.payload,
				adventCalendar: {
					...state.adventCalendar,
					doors: updateDoorInCalendarDoors(state.adventCalendar.doors, action.payload)
				}
			};
		case SEND_ADVENT_CALENDAR_DOOR_CODE.FAILURE:
			return {
				...state,
				isSubmittingDoorCode: false,
			};
		case SET_SELECTED_ADVENT_CALENDAR_DOOR:
			return {
				...state,
				selectedDoor: action.payload
			};
		default:
			return {
				...state
			};
	}
};
export default advent;