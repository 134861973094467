import type {Axios, AxiosPromise} from "axios";
import {axiosInstance} from "../../api";
import type {
	AdventCalendarAccessoryNavigatorOrderModel,
	AdventCalendarCodeModel,
	AdventCalendarDoorModel
} from "../model";
import {AdventCalendarOpenDoorModel} from "../model";

class AdventService {
	api: Axios;

	constructor() {
		this.api = axiosInstance;
	}

	getAdventCalendar = (): AxiosPromise => {
		return this.api
			.get("/advent/byDealer")
			.then(response => response.data);
	};

	openAdventCalendarDoor = (request: AdventCalendarOpenDoorModel): AxiosPromise<AdventCalendarDoorModel> => {
		return this.api.post(`advent/door/${request.doorId}/open`, {
			...request
		});
	};

	sendAdventCalendarCode = (request: AdventCalendarCodeModel): AxiosPromise<AdventCalendarDoorModel> => {
		return this.api.post(
			`advent/door/${request.doorId}/code`,
			{},
			{
				params: {
					doorCode: request.doorCode
				}
			}
		);
	};
	
	sendAccessoryNavigatorPromotionOrder = (request: AdventCalendarAccessoryNavigatorOrderModel): AxiosPromise<AdventCalendarDoorModel> => {
		return this.api.post(
			`advent/door/${request.doorId}/order`, {
				...request
			});
	};
}

const adventService = new AdventService();
export default adventService;