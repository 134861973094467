import ThunkAction from "redux-thunk";
import adventService from "../api/adventService";
import orderService from "../../order/api/orderService";
import type {
	AdventCalendarAccessoryNavigatorOrderModel,
	AdventCalendarArticleOrderModel,
	AdventCalendarCodeModel,
	AdventCalendarDoorModel,
	AdventCalendarOpenDoorModel,
} from "../model";
import {push} from "connected-react-router";
import RouteDictionary from "../../RouteDictionary";
import {CREATE_ORDERS_FAILURE, CREATE_ORDERS_REQUEST, CREATE_ORDERS_SUCCESS} from "../../order/actions/offer";
import type {CreateOrderRequest} from "../../order/models";

export const SET_ADVENT_CALENDAR_DOOR_MODAL = "SET_ADVENT_CALENDAR_DOOR_MODAL";
export const SET_SELECTED_ADVENT_CALENDAR_DOOR = "SET_SELECTED_ADVENT_CALENDAR_DOOR";

export const FETCH_ADVENT_CALENDAR = {
	REQUEST: "FETCH_ADVENT_CALENDAR_REQUEST",
	SUCCESS: "FETCH_ADVENT_CALENDAR_SUCCESS",
	FAILURE: "FETCH_ADVENT_CALENDAR_FAILURE",
};

export const OPEN_ADVENT_CALENDAR_DOOR = {
	REQUEST: "OPEN_ADVENT_CALENDAR_DOOR_REQUEST",
	SUCCESS: "OPEN_ADVENT_CALENDAR_DOOR_SUCCESS",
	FAILURE: "OPEN_ADVENT_CALENDAR_DOOR_FAILURE",
};

export const SEND_ADVENT_CALENDAR_DOOR_CODE = {
	REQUEST: "SEND_ADVENT_CALENDAR_DOOR_CODE_REQUEST",
	SUCCESS: "SEND_ADVENT_CALENDAR_DOOR_CODE_SUCCESS",
	FAILURE: "SEND_ADVENT_CALENDAR_DOOR_CODE_FAILURE",
};

export const SEND_ADVENT_CALENDAR_DOOR_ORDER = {
	REQUEST: "SEND_ADVENT_CALENDAR_DOOR_ORDER_REQUEST",
	SUCCESS: "SEND_ADVENT_CALENDAR_DOOR_ORDER_SUCCESS",
	FAILURE: "SEND_ADVENT_CALENDAR_DOOR_ORDER_FAILURE",
};

export const SEND_ADVENT_CALENDAR_DOOR_ACCESSORY_NAVIGATOR_ORDER = {
	REQUEST: "SEND_ADVENT_CALENDAR_DOOR_ACCESSORY_NAVIGATOR_ORDER_REQUEST",
	SUCCESS: "SEND_ADVENT_CALENDAR_DOOR_ACCESSORY_NAVIGATOR_ORDER_SUCCESS",
	FAILURE: "SEND_ADVENT_CALENDAR_DOOR_ACCESSORY_NAVIGATOR_ORDER_FAILURE",
};

export const fetchAdventCalendar = (): ThunkAction => {
	return dispatch => {
		dispatch({
			type: FETCH_ADVENT_CALENDAR.REQUEST
		});
		adventService.getAdventCalendar()
			.then(adventCalendar => {
				return dispatch({
					type: FETCH_ADVENT_CALENDAR.SUCCESS,
					payload: adventCalendar
				});
			})
			.catch(() => {
				return dispatch({
						type: FETCH_ADVENT_CALENDAR.FAILURE
					}
				);
			});
	};
};

export const openAdventCalendarDoor = (request: AdventCalendarOpenDoorModel): ThunkAction => {
	return dispatch => {
		dispatch({type: OPEN_ADVENT_CALENDAR_DOOR.REQUEST});
		adventService.openAdventCalendarDoor(request)
			.then(response => {
				dispatch({
					type: OPEN_ADVENT_CALENDAR_DOOR.SUCCESS,
					payload: response.data
				});
			})
			.catch(() => {
				return dispatch({
					type: OPEN_ADVENT_CALENDAR_DOOR.FAILURE
				});
			});
	};
};

export const sendAdventCalendarCode = (request: AdventCalendarCodeModel): ThunkAction => {
	return dispatch => {
		dispatch({type: SEND_ADVENT_CALENDAR_DOOR_CODE.REQUEST});
		adventService.sendAdventCalendarCode(request)
			.then(response => {
				dispatch({
					type: SEND_ADVENT_CALENDAR_DOOR_CODE.SUCCESS,
					payload: response.data
				});
			})
			.catch(e => {
				console.error(e);
				return dispatch({
					type: SEND_ADVENT_CALENDAR_DOOR_CODE.FAILURE
				});
			});
	};
};

export const sendArticleOrder = (request: AdventCalendarArticleOrderModel): ThunkAction => {
	return dispatch => {
		dispatch({type: CREATE_ORDERS_REQUEST});
		const order: CreateOrderRequest = {
			orderName: "AdventOrder",
			salesPromotionId: 0,
			orderTypeId: 3,
			orderItems: [
				{
					partNumber: request.treat.partNumber,
					quantity: request.amount,
					price: request.treat.specialPrice,
					note: ""
				}
			]
		};
		orderService.createOrder(order)
			.then(response => {
				dispatch({
					type: CREATE_ORDERS_SUCCESS,
					orderBundle: response.data
				});
				dispatch(push(RouteDictionary.getRoutes().ORDER));
			})
			.catch(reason => {
				return dispatch({
					type: CREATE_ORDERS_FAILURE,
					errorMessage: reason.response.data.message
				});
			});
	};
};

export const sendAccessoryNavigatorOrder = (request : AdventCalendarAccessoryNavigatorOrderModel): ThunkAction => {
	return dispatch => {
		dispatch({type: SEND_ADVENT_CALENDAR_DOOR_ACCESSORY_NAVIGATOR_ORDER.REQUEST});
		adventService.sendAccessoryNavigatorPromotionOrder(request)
			.then(response => {
				dispatch({type: SEND_ADVENT_CALENDAR_DOOR_ACCESSORY_NAVIGATOR_ORDER.SUCCESS,
				payload: response.data});
			})
			.catch(reason => {
				return dispatch({
					type: SEND_ADVENT_CALENDAR_DOOR_ACCESSORY_NAVIGATOR_ORDER.FAILURE,
					errorMessage: reason.response.data.message
				});
			});
	};
};

export const setDoorContentModal = (payload: boolean) => {
	return {
		type: SET_ADVENT_CALENDAR_DOOR_MODAL,
		payload
	};
};

export const setSelectedDoor = (door: AdventCalendarDoorModel) => {
	return dispatch => {
		dispatch({
			type: SET_SELECTED_ADVENT_CALENDAR_DOOR,
			payload: door
		});
	};
};