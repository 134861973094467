import type {State} from "../../reducers/rootReducer";
import type {FacetModel} from "../../components/filter/model";
import type {MaintenanceIllustration} from "../model";
import {MaintenanceGuideTimeModel} from "../model";
import {
	FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS,
	FETCH_MAINTENANCE_GUIDE_TIME_MODELS,
	FETCH_MAINTENANCE_SECTIONS_FACETS,
	RESET_MAINTENANCE_GUIDE_TIME_SEARCH,
	SET_MAINTENANCE_GUIDE_TIME_FILTER,
	SET_SELECTED_MAINTENANCE_CATEGORY
} from "../actions/vehicleMaintenanceGuideTimes";
import {RESET_VEHICLE} from "../../vehicle/action";

export type VehicleMaintenanceGuideTimeSearch = {
	maintenanceSectionFacets: FacetModel[],
	categoryId: number,
	maintenanceIllustrations: MaintenanceIllustration[],
	matchingMaintenanceGuideTimes: MaintenanceGuideTimeModel[],
	isMaintenanceGuideTimeFilterActive: boolean,
	isFetchingMaintenanceSectionsFacets: boolean,
	isFetchingMaintenanceIllustrations: boolean,
	isFetchingMatchingMaintenanceGuideTimeModels: boolean
}

const initialState = {
	maintenanceSectionFacets: [],
	categoryId: null,
	maintenanceIllustrations: [],
	matchingMaintenanceGuideTimes: [],
	isMaintenanceGuideTimeFilterActive: false,
	isFetchingMaintenanceSectionsFacets: false,
	isFetchingMaintenanceIllustrations: false,
	isFetchingMatchingMaintenanceGuideTimeModels: false
};

const vehicleGuideTimeSearch = (state: State = initialState, action) => {
	switch (action.type) {
		case SET_SELECTED_MAINTENANCE_CATEGORY:
			return {
				...state,
				categoryId: action.payload
			};
		case RESET_MAINTENANCE_GUIDE_TIME_SEARCH:
			return {
				...state,
				categoryId: null,
				maintenanceIllustrations: [],
				matchingMaintenanceGuideTimes: [],
				isMaintenanceGuideTimeFilterActive: false
			};
		case SET_MAINTENANCE_GUIDE_TIME_FILTER:
			return {
				...state,
				isMaintenanceGuideTimeFilterActive: action.payload
			};
		case FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS.REQUEST:
			return {
				...state,
				isFetchingMaintenanceIllustrations: true
			};
		case FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS.SUCCESS:
			return {
				...state,
				maintenanceIllustrations: action.payload,
				isFetchingMaintenanceIllustrations: false
			};
		case FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS.FAILURE:
			return {
				...state,
				isFetchingMaintenanceIllustrations: false
			};
		case FETCH_MAINTENANCE_SECTIONS_FACETS.REQUEST:
			return {
				...state,
				isFetchingMaintenanceSectionsFacets: true
			};
		case FETCH_MAINTENANCE_SECTIONS_FACETS.SUCCESS:
			return {
				...state,
				maintenanceSectionFacets: action.payload,
				isFetchingMaintenanceSectionsFacets: false
			};
		case FETCH_MAINTENANCE_SECTIONS_FACETS.FAILURE:
			return {
				...state,
				isFetchingMaintenanceSectionsFacets: false
			};
		case FETCH_MAINTENANCE_GUIDE_TIME_MODELS.REQUEST:
			return {
				...state,
				isFetchingMatchingMaintenanceGuideTimeModels: true
			};
		case FETCH_MAINTENANCE_GUIDE_TIME_MODELS.SUCCESS:
			return {
				...state,
				matchingMaintenanceGuideTimes: action.payload.maintenanceGuideTimes,
				isFetchingMatchingMaintenanceGuideTimeModels: false
			};
		case FETCH_MAINTENANCE_GUIDE_TIME_MODELS.FAILURE:
			return {
				...state,
				isFetchingMatchingMaintenanceGuideTimeModels: false
			};
		case RESET_VEHICLE:
			return {
				...state,
				categoryId: null,
				maintenanceIllustrations: [],
				matchingMaintenanceGuideTimes: [],
				isMaintenanceGuideTimeFilterActive: false
			};
		default:
			return state;
	}
};

export default vehicleGuideTimeSearch;