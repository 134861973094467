import maintenanceService from "../service/maintenanceService";
import type {VkCode} from "../../vehicle/model";

export const SET_SELECTED_MAINTENANCE_CATEGORY = "SET_SELECTED_MAINTENANCE_CATEGORY";
export const SET_MAINTENANCE_GUIDE_TIME_FILTER = "SET_MAINTENANCE_GUIDE_TIME_FILTER";
export const RESET_MAINTENANCE_GUIDE_TIME_SEARCH = "RESET_MAINTENANCE_GUIDE_TIME_SEARCH";
export const FETCH_MAINTENANCE_SECTIONS_FACETS = {
	REQUEST: "FETCH_MAINTENANCE_SECTIONS_REQUEST",
	SUCCESS: "FETCH_MAINTENANCE_SECTIONS_SUCCESS",
	FAILURE: "FETCH_MAINTENANCE_SECTIONS_FAILURE",
};

export const FETCH_MAINTENANCE_GUIDE_TIME_MODELS = {
	REQUEST: "FETCH_MAINTENANCE_GUIDE_TIME_MODELS_REQUEST",
	SUCCESS: "FETCH_MAINTENANCE_GUIDE_TIME_MODELS_SUCCESS",
	FAILURE: "FETCH_MAINTENANCE_GUIDE_TIME_MODELS_FAILURE",
};

export const FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS = {
	REQUEST: "FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS_REQUEST",
	SUCCESS: "FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS_SUCCESS",
	FAILURE: "FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS_FAILURE",
};

export const setSelectedMaintenanceCategory = (categoryId: number) => {
	return dispatch => {
		dispatch(
			{
				type: SET_SELECTED_MAINTENANCE_CATEGORY,
				payload: categoryId
			});
	};
};

export const resetMaintenanceGuideTimeSearch = () => {
	return dispatch => {
		dispatch({type: RESET_MAINTENANCE_GUIDE_TIME_SEARCH});
	};
};

export const setMaintenanceGuideTimeFilter = (isActive: boolean) => {
	return dispatch => {
		dispatch(
			{
				type: SET_MAINTENANCE_GUIDE_TIME_FILTER,
				payload: isActive
			});
	};
};

export function fetchMaintenanceCategoryIllustrations(categoryId: number) {
	return async dispatch => {
		dispatch({type: FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS.REQUEST});
		try {
			const illustrations = await maintenanceService.getCategoryIllustrations(categoryId);
			dispatch({
				type: FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS.SUCCESS,
				payload: illustrations
			});
		} catch (e) {
			dispatch({type: FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS.FAILURE});
			console.error(e);
		}
	};
}

export function fetchMaintenanceSectionFacetsByVehicle(vkCode: VkCode) {
	return async dispatch => {
		const {modelYear, type} = vkCode;
		dispatch({type: FETCH_MAINTENANCE_SECTIONS_FACETS.REQUEST});
		try {
			const sectionFacets = await maintenanceService.getSectionFacetsByVehicle(type, modelYear);
			dispatch({
				type: FETCH_MAINTENANCE_SECTIONS_FACETS.SUCCESS,
				payload: sectionFacets
			});
		} catch (e) {
			dispatch({type: FETCH_MAINTENANCE_SECTIONS_FACETS.FAILURE});
			console.error(e);
		}
	};
}

export const getMaintenanceFittingsByCategoryAndModuleNumbersAndVehicle = (vkCode: VkCode, categoryId: number, moduleNumbers: string[]) => {
	return async dispatch => {
		const {modelYear, type} = vkCode;
		dispatch({type: FETCH_MAINTENANCE_GUIDE_TIME_MODELS.REQUEST});
		try {
			const matchingGuideTimes = await maintenanceService
				.getFittingsByCategoryAndModuleNumbersAndVehicle(
					categoryId,
					moduleNumbers,
					type,
					modelYear
				);
			dispatch({
				type: FETCH_MAINTENANCE_GUIDE_TIME_MODELS.SUCCESS,
				payload: matchingGuideTimes
			});
		} catch (e) {
			dispatch({type: FETCH_MAINTENANCE_GUIDE_TIME_MODELS.FAILURE});
			console.error(e);
		}
	};
};